import { NotificationsOutlined, SignalCellularAlt } from "@mui/icons-material";
import CardSection from "../../components/Sections/CardSection";
import PageComponent from "../../components/Page/PageComponent";
import NotificationList from "../../components/Notifications/NotificationList";
import VisitorsCard from "../../components/Visitors/VisitorsCard";

const Home = () => {
  return (
    <PageComponent
      headerTitle="Kezelőfelület"
      breadCrumbMain="Alap"
      children={
        <>
          <CardSection
            icon={NotificationsOutlined}
            title="Értesítések"
            children={
              <>
                <NotificationList maxNotifications={4} button={true} />
              </>
            }
          />
          <CardSection
            icon={SignalCellularAlt}
            title="Látogatói statisztika"
            children={
              <>
                <VisitorsCard />
              </>
            }
          />
        </>
      }
    />
  );
};

export default Home;
