import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import EditNoteIcon from "@mui/icons-material/EditNote";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useState } from "react";
import PrimaryButton from "../Buttons/PrimaryButton";

interface WebsiteModel {
  id: number;
  status: boolean;
  address: string;
  registrationDate: string;
  owner: string;
}

const mockSites: WebsiteModel[] = [
  {
    id: 1,
    status: true,
    address: "www.tesztweb1.hu",
    registrationDate: "2024-04-04 13:22",
    owner: "Teszt Elek",
  },
  {
    id: 2,
    status: true,
    address: "www.tesztweb2.hu",
    registrationDate: "2024-05-06 12:21",
    owner: "Teszt Tibor",
  },
  {
    id: 3,
    status: true,
    address: "www.tesztweb3.hu",
    registrationDate: "2023-07-11 05:54",
    owner: "Teszt Géza",
  },
  {
    id: 4,
    status: false,
    address: "www.tesztweb4.hu",
    registrationDate: "2024-12-30 23:11",
    owner: "Teszt Márton",
  },
  {
    id: 5,
    status: true,
    address: "www.tesztweb1.hu",
    registrationDate: "2024-04-04 13:22",
    owner: "Teszt Elek",
  },
  {
    id: 6,
    status: true,
    address: "www.tesztweb2.hu",
    registrationDate: "2024-05-06 12:21",
    owner: "Teszt Tibor",
  },
  {
    id: 7,
    status: true,
    address: "www.tesztweb3.hu",
    registrationDate: "2023-07-11 05:54",
    owner: "Teszt Géza",
  },
  {
    id: 8,
    status: false,
    address: "www.tesztweb4.hu",
    registrationDate: "2024-12-30 23:11",
    owner: "Teszt Márton",
  },
  {
    id: 9,
    status: true,
    address: "www.tesztweb1.hu",
    registrationDate: "2024-04-04 13:22",
    owner: "Teszt Elek",
  },
  {
    id: 10,
    status: true,
    address: "www.tesztweb2.hu",
    registrationDate: "2024-05-06 12:21",
    owner: "Teszt Tibor",
  },
  {
    id: 11,
    status: true,
    address: "www.tesztweb3.hu",
    registrationDate: "2023-07-11 05:54",
    owner: "Teszt Géza",
  },
  {
    id: 12,
    status: false,
    address: "www.tesztweb4.hu",
    registrationDate: "2024-12-30 23:11",
    owner: "Teszt Márton",
  },
  {
    id: 13,
    status: true,
    address: "www.tesztweb1.hu",
    registrationDate: "2024-04-04 13:22",
    owner: "Teszt Elek",
  },
  {
    id: 14,
    status: true,
    address: "www.tesztweb2.hu",
    registrationDate: "2024-05-06 12:21",
    owner: "Teszt Tibor",
  },
  {
    id: 15,
    status: true,
    address: "www.tesztweb3.hu",
    registrationDate: "2023-07-11 05:54",
    owner: "Teszt Géza",
  },
  {
    id: 16,
    status: false,
    address: "www.tesztweb4.hu",
    registrationDate: "2024-12-30 23:11",
    owner: "Teszt Márton",
  },
  {
    id: 17,
    status: true,
    address: "www.tesztweb1.hu",
    registrationDate: "2024-04-04 13:22",
    owner: "Teszt Elek",
  },
  {
    id: 18,
    status: true,
    address: "www.tesztweb2.hu",
    registrationDate: "2024-05-06 12:21",
    owner: "Teszt Tibor",
  },
  {
    id: 19,
    status: true,
    address: "www.tesztweb3.hu",
    registrationDate: "2023-07-11 05:54",
    owner: "Teszt Géza",
  },
  {
    id: 20,
    status: false,
    address: "www.tesztweb4.hu",
    registrationDate: "2024-12-30 23:11",
    owner: "Teszt Elemér",
  },
];

const WebsitePage = () => {
  const [filteredList, setFilteredList] = useState(mockSites);

  const filterTable = (value: string) => {
    if (value) {
      setFilteredList(
        mockSites.filter((item) =>
          item.owner.toLowerCase().includes(value.toLowerCase())
        )
      );
    } else {
      setFilteredList(mockSites);
    }
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box>
        <PrimaryButton icon={<EditNoteIcon />} title="Weboldal regisztráció" />
      </Box>

      <TextField
        id="outlined-basic"
        label="Szűrés"
        variant="outlined"
        sx={{ marginTop: 4, marginBottom: 4 }}
        onChange={(e) => {
          filterTable(e.target.value);
        }}
      />
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          sx={{ minWidth: 650, maxHeight: "100%" }}
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell>Státusz</TableCell>
              <TableCell align="right">Cím</TableCell>
              <TableCell align="right">Tulajdonos</TableCell>
              <TableCell align="right">Regisztráció</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredList.map((mockSite) => (
              <TableRow
                key={mockSite.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {mockSite.status ? <DoneIcon /> : <CloseIcon />}
                </TableCell>
                <TableCell align="right">{mockSite.address}</TableCell>
                <TableCell align="right">{mockSite.owner}</TableCell>
                <TableCell align="right">{mockSite.registrationDate}</TableCell>
                <TableCell>
                  <PrimaryButton icon={<EditNoteIcon />} title="Szerkesztés" />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default WebsitePage;
