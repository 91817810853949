import { Typography } from "@mui/material";
import React from "react";

interface SidebarSectionProps {
  text: string;
}

const SidebarSection = ({ text }: SidebarSectionProps) => {
  return (
    <Typography
      sx={{ padding: "1%", paddingLeft: "5%", color: "#455a64", fontSize: "12px", fontWeight: "600" }}
    >
      {text}
    </Typography>
  );
};

export default SidebarSection;
