import React from "react";
import PageComponent from "../../components/Page/PageComponent";
import CardSection from "../../components/Sections/CardSection";
import { Facebook, FeedOutlined } from "@mui/icons-material";
import FacebookFeed from "../../components/Facebook/FacebookFeed";

const FacebookPage = () => {
  return (
    <PageComponent
      headerTitle="Facebook"
      breadCrumbMain="Integrációk"
      children={
        <>
          <CardSection
            icon={Facebook}
            title="Facebook integráció"
            children={<></>}
          />
          <CardSection
            icon={FeedOutlined}
            title="Facebook bejegyzések"
            children={<FacebookFeed />}
          />
        </>
      }
    />
  );
};

export default FacebookPage;
