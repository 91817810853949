import { jwtDecode } from "jwt-decode";
import IUser from "../types/User.type";

export const getToken = () => {
  return localStorage.getItem("authToken");
};

export const getActualUserWebsiteId = () => {
  return jwtDecode<IUser>(getToken()!).website;
};

export const getActualUserPermissions = () => {
  return jwtDecode<any>(getToken()!).permissions;
};

export const getActualUserIsAdmin = () => {
  const token = getToken();
  if (token) {
    const decoded = jwtDecode<any>(token);
    if (decoded.role === "ADMIN") {
      return true;
    }
  }
  return false;
};

export const getTokenExpiration = () => {
  const token = getToken();
  if (token) {
    const decoded = jwtDecode<any>(token);
    return decoded.exp;
  }
  return null;
};
