import { Close, EmailOutlined, SaveOutlined } from "@mui/icons-material";
import { Box, DialogActions, IconButton, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import * as React from "react";
import PrimaryButton from "../Buttons/PrimaryButton";

interface PagesDialogProps {
  title?: string;
  icon?: React.ReactNode;
  content: React.ReactNode;
  savePage?: () => void;
  handleOpen?: () => void;
  handleClose?: () => void;
  isOpen: boolean;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PagesDialog: React.FC<PagesDialogProps> = (props) => {
  return (
    <Dialog
      fullWidth={true}
      open={props.isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.handleClose}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        sx: {
          boxShadow: 3,
          borderRadius: 3,
          borderColor: "#303030",
          borderStyle: "solid",
          borderWidth: 1,
        },
      }}
      sx={{
        "& .MuiDialogPaper-root": {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        "& .MuiDialogTitle-root": {
          paddingBottom: 2,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        "& .MuiDialogContent-root": {
          padding: "5px",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        "& .MuiDialogActions-root": {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
      }}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center">
          {props.icon ? props.icon : <EmailOutlined sx={{ marginRight: 2 }} />}
          <Box display="flex" flexDirection="column">
            <Typography>{props.title}</Typography>
          </Box>
        </Box>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={props.handleClose}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: "#4dabf5",
        })}
      >
        <Close />
      </IconButton>
      <DialogContent>{props.content}</DialogContent>
      <DialogActions>
        <PrimaryButton
          onClick={props.savePage}
          icon={<SaveOutlined />}
          title="Mentés"
        />
      </DialogActions>
    </Dialog>
  );
};

export default PagesDialog;
