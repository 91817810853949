import React, { useState, ChangeEvent } from "react";
import { Button, Box, Alert } from "@mui/material";
import { AttachFileOutlined } from "@mui/icons-material";
import GalleryService from "../../services/Gallery.service";
import { getActualUserWebsiteId } from "../../utils/token";

interface AddImageProps {
  refreshGallery: () => void;
}

const AddImage: React.FC<AddImageProps> = ({ refreshGallery }) => {
  const [file, setFile] = useState<File | null>(null);
  const [uploadSuccess, setUploadSuccess] = useState<boolean | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [fileError, setFileError] = useState<string | null>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      const validImageTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/webp",
      ];
      if (!validImageTypes.includes(selectedFile.type)) {
        setFileError(
          "Csak kép formátumú fájlokat tölthetsz fel! (JPEG, PNG, GIF, WEBP)"
        );
        setFile(null);
        return;
      }
      setFile(selectedFile);
      setFileError(null);
      setUploadSuccess(null);
    }
  };

  const handleFileUpload = async () => {
    if (file) {
      const formData = new FormData();
      formData.append("image", file);
      formData.append("websiteId", "" + getActualUserWebsiteId());

      setIsUploading(true);
      try {
        await GalleryService.upload(formData);
        setUploadSuccess(true);
        refreshGallery();
        setFileError(null);
      } catch (error) {
        setUploadSuccess(false);
      } finally {
        setIsUploading(false);
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 2,
        maxWidth: 400,
        margin: "auto",
        textAlign: "center",
      }}
    >
      <Button
        variant="outlined"
        component="label"
        startIcon={<AttachFileOutlined />}
        disabled={isUploading}
        sx={{
          width: "100%",
          marginTop: 1,
          color: "white",
          borderRadius: "8px",
          boxShadow: "1",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "#263238",
            boxShadow: 0,
            transition: "background-color 0.3s ease",
          },
        }}
      >
        Kép tallózása
        <input type="file" hidden onChange={handleFileChange} />
      </Button>

      {file ? (
        fileError ? (
          <Alert sx={{ margin: "10px", width: "100%" }} severity="error">
            {fileError}
          </Alert>
        ) : uploadSuccess === null ? (
          <Alert sx={{ margin: "10px", width: "100%" }} severity="success">
            {file.name}
          </Alert>
        ) : uploadSuccess ? (
          <Alert sx={{ margin: "10px", width: "100%" }} severity="success">
            {file.name} sikeresen feltöltve!
          </Alert>
        ) : (
          <Alert sx={{ margin: "10px", width: "100%" }} severity="error">
            {file.name} feltöltése sikertelen.
          </Alert>
        )
      ) : (
        <Alert sx={{ margin: "10px", width: "100%" }} severity="info">
          A fájl kép formátumú lehet.
        </Alert>
      )}

      <Button
        variant="contained"
        onClick={handleFileUpload}
        disabled={!file || isUploading}
        sx={{
          width: "100%",
          backgroundColor: isUploading ? "#455a64" : "#1c262d",
          color: "white",
          borderRadius: "8px",
          boxShadow: "1",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "#263238",
            boxShadow: 0,
            transition: "background-color 0.3s ease",
          },
        }}
      >
        {isUploading ? "Feltöltés..." : "Feltöltés"}
      </Button>
    </Box>
  );
};

export default AddImage;
