import { Box, Grid, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import UserCard from "./UserCard";
import IUser from "../../types/User.type";
import UserService from "../../services/User.service";

interface UserListProps {
  button?: boolean;
  maxUsers?: number;
}

const UserList: React.FC<UserListProps> = (props) => {
  const [data, setData] = useState<IUser[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    UserService.getAll()
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching users:", err);
        setLoading(false);
      });
  }, []);

  return (
    <Box sx={{ margin: "auto" }}>
      <Grid container spacing={2}>
        {loading
          ? Array.from({ length: 6 }).map((_, index) => (
              <Grid item xs={12} sm={12} md={6} key={index}>
                <Skeleton
                  sx={{ bgcolor: "grey.900" }}
                  animation="wave"
                  variant="rectangular"
                  width="100%"
                  height={150}
                />
              </Grid>
            ))
          : data.map((user, index) => (
              <Grid item xs={12} sm={12} md={6} key={index}>
                <UserCard
                  email={user.email}
                  role={user.role}
                  permissions={user.permissions}
                  website={user.website}
                />
              </Grid>
            ))}
      </Grid>
    </Box>
  );
};

export default UserList;
