import { Breadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";

interface BreadCrumbProps {
  mainTitle: string;
  title: string;
}

const BreadCrumb: React.FC<BreadCrumbProps> = (props) => {
  return (
    <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: 12 }}>
      <Link
        to="/dashboard"
        style={{ textDecoration: "none", color: "inherit" }}
      >
        {props.mainTitle}
      </Link>
      <Typography color="text.primary">{props.title}</Typography>
    </Breadcrumbs>
  );
};

export default BreadCrumb;
