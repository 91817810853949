import http from "../utils/http-common";
import IPages from "../types/Pages.type";

class PagesService {
  getAll() {
    return http.get<Array<IPages>>("/api/pages/");
  }

  editPage(id: any, data: IPages) {
    return http.patch<IPages>(`/api/pages/${id}`, data);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PagesService();
