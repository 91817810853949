import React, { useRef } from "react";
import ReactQuill from "react-quill-new";
import "react-quill/dist/quill.snow.css";
import "../../assets/QuillEditor.css";

interface QuillEditorProps {
  value: string;
  onChange: (value: string) => void;
}

const QuillEditor: React.FC<QuillEditorProps> = ({ value, onChange }) => {
  const quillRef = useRef<ReactQuill | null>(null);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      [
        {
          color: [
            "#aa8c33",
            "red",
            "blue",
            "green",
            "black",
            "orange",
            "white",
            "rgba(255, 255, 255, 0.8)",
          ],
        },
      ],
    ],
  };

  const formats = ["header", "bold", "italic", "underline", "list", "color"];

  return (
    <ReactQuill
      ref={quillRef}
      value={value}
      onChange={onChange}
      modules={modules}
      formats={formats}
      theme="snow"
    />
  );
};

export default React.memo(QuillEditor);
