import http from "../utils/http-common";
import IVisitor from "../types/Visitor.type";

class VisitorDataService {
  getAll(websiteId: any) {
    return http.get<IVisitor>(`/api/visitor/getAllVisitors/${websiteId}`);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new VisitorDataService();
