import React from "react";
import PageComponent from "../../components/Page/PageComponent";

const Google = () => {
  return (
    <PageComponent
      headerTitle="Google"
      breadCrumbMain="Integrációk"
      children={<></>}
    />
  );
};

export default Google;
