import { ArticleOutlined, EditNoteOutlined } from "@mui/icons-material";
import { Box, Card, CardContent, IconButton, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import IPages from "../../types/Pages.type";
import PagesDialog from "./PagesDialog";
import QuillEditor from "./QuillEditor";
import PagesService from "../../services/Pages.service";
import { useSnackbar } from "notistack";

interface PagesCardProps extends IPages {
  refreshPage: (updatedPage: IPages) => void;
}

const PagesCard: React.FC<PagesCardProps> = (props) => {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState<string>(props.content);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setContent(props.content);
  }, [props.content]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    const updatedPage = { ...props, content };
    props.refreshPage(updatedPage);
  };

  const handleContentChange = (value: string) => {
    setContent(value);
  };

  async function handleSave(): Promise<void> {
    setOpen(false);
    try {
      await PagesService.editPage(props.id, {
        content: content,
      });
      setOpen(false);
      enqueueSnackbar("Sikeres mentés!", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Sikertelen mentés!", { variant: "error" });
    }
  }

  return (
    <Card
      sx={{
        marginBottom: 1,
        boxShadow: 3,
        borderRadius: 3,
        borderColor: "#303030",
        borderStyle: "solid",
        borderWidth: 1,
      }}
    >
      <CardContent>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" gap={1}>
            <ArticleOutlined />
            <Typography variant="body1" color="text.primary">
              {props.title}
            </Typography>
          </Box>
          <Typography variant="body1" color="text.primary">
            <IconButton
              edge="end"
              aria-label="edit"
              onClick={handleOpen}
              sx={{
                color: "#4dabf5",
                marginRight: "2px",
              }}
            >
              <EditNoteOutlined />
            </IconButton>
          </Typography>
        </Box>
      </CardContent>
      <PagesDialog
        isOpen={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        title={props.title}
        icon={<ArticleOutlined sx={{ marginRight: 2 }} />}
        savePage={handleSave}
        content={<QuillEditor value={content} onChange={handleContentChange} />}
      />
    </Card>
  );
};

export default PagesCard;
