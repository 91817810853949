import * as React from "react";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { Card, Typography, Link, Chip } from "@mui/material";
import { DeleteOutline, EditNoteOutlined } from "@mui/icons-material";
import IUser from "../../types/User.type";

const UserCard: React.FC<IUser> = (props) => {
  const initials = props.email ? props.email.slice(0, 1).toUpperCase() : "";

  return (
    <Box sx={{ flexGrow: 1, maxWidth: 752 }}>
      <Card
        sx={{
          marginBottom: 1,
          boxShadow: 3,
          borderRadius: 3,
          borderColor: "#303030",
          borderStyle: "solid",
          borderWidth: 1,
        }}
      >
        <ListItem
          secondaryAction={
            <>
              <IconButton
                edge="end"
                aria-label="edit"
                sx={{
                  color: "#4dabf5",
                  marginRight: "2px",
                }}
              >
                <EditNoteOutlined />
              </IconButton>
              <IconButton
                edge="end"
                aria-label="delete"
                sx={{
                  color: "#4dabf5",
                }}
              >
                <DeleteOutline />
              </IconButton>
            </>
          }
        >
          <ListItemAvatar>
            <Avatar
              sx={{
                borderColor: "#4dabf5",
                borderStyle: "solid",
                color: "white",
                backgroundColor: "transparent",
              }}
            >
              {initials}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={props.email}
            secondary={
              <>
                <span style={{ display: "block" }}>
                  {props.role === "ADMIN" ? "Adminisztrátor" : "Felhasználó"}
                </span>
                <span style={{ display: "block" }}>
                  Weboldal:{" "}
                  <Link
                    href={`http://${props.website?.url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {props.website?.url}
                  </Link>
                </span>
              </>
            }
          />
        </ListItem>
        <Box sx={{ padding: 1 }}>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 1,
              paddingLeft: 2,
              paddingBottom: 1,
            }}
          >
            {props.permissions ? (
              props.permissions.length > 0 ? (
                props.permissions.map((permission, index) => (
                  <Chip
                    key={index}
                    label={permission.name}
                    variant="outlined"
                    onDelete={() => <></>}
                    deleteIcon={<DeleteIcon />}
                    sx={{
                      border: "1px solid #303030",
                      color: "white",
                    }}
                  />
                ))
              ) : (
                <Typography variant="body2" color="textSecondary">
                  Nincs funkció a felhasználóhoz rendelve
                </Typography>
              )
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default UserCard;
