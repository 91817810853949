import {
  AddPhotoAlternateOutlined,
  CollectionsOutlined,
} from "@mui/icons-material";
import PageComponent from "../../components/Page/PageComponent";
import CardSection from "../../components/Sections/CardSection";
import GalleryList from "../../components/Gallery/GalleryList";
import AddImage from "../../components/Gallery/AddImage";
import { useState, useEffect } from "react";
import GalleryService from "../../services/Gallery.service";
import IGallery from "../../types/Gallery.type";
import { getActualUserWebsiteId } from "../../utils/token";

const Gallery = () => {
  const [images, setImages] = useState<IGallery[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const refreshGallery = () => {
    setLoading(true);
    GalleryService.getAll(getActualUserWebsiteId())
      .then((response) => {
        if (Array.isArray(response.data)) {
          setImages(response.data as IGallery[]);
          setError(null);
        } else {
          setError("Invalid response format received from the server.");
          setImages([]);
        }
      })
      .catch(() => {
        setError("Nem sikerült betölteni a képeket. Próbáld újra!");
        setImages([]);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    refreshGallery();
  }, []);

  return (
    <PageComponent
      headerTitle="Galéria"
      breadCrumbMain="Alap"
      children={
        <>
          <CardSection
            icon={AddPhotoAlternateOutlined}
            title="Új kép hozzáadása"
            children={<AddImage refreshGallery={refreshGallery} />}
          />
          <CardSection
            icon={CollectionsOutlined}
            title="Feltöltött képek"
            children={
              <GalleryList
                images={images}
                loading={loading}
                error={error}
                refreshGallery={refreshGallery}
              />
            }
          />
        </>
      }
    />
  );
};

export default Gallery;
