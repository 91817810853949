import { ArticleOutlined } from "@mui/icons-material";
import PageComponent from "../../components/Page/PageComponent";
import PagesList from "../../components/Pages/PagesList";
import CardSection from "../../components/Sections/CardSection";

const Pages = () => {
  return (
    <PageComponent
      headerTitle="Oldalak"
      breadCrumbMain="Alap"
      children={
        <CardSection
          icon={ArticleOutlined}
          title="Oldalak"
          children={<PagesList />}
        />
      }
    />
  );
};

export default Pages;
