import http from "../utils/http-common";
import INotification from "../types/Notification.type";

class NotificationService {
  getAll() {
    return http.get<Array<INotification>>("/api/notifications/");
  }

  setRead(id: any) {
    return http.patch<INotification>(`/api/notifications/setRead/${id}`);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new NotificationService();
