import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { forwardRef } from "react";
import { useLocation } from "react-router-dom";

interface ListItemComponentProps {
  text: string;
  icon: JSX.Element;
  route: string;
  open: boolean;
  disabled?: boolean;
  onClick?: () => void; // Add the optional onClick prop
}

// Forward ref to allow Tooltip to properly access the ref
const SideBarItem = forwardRef<HTMLDivElement, ListItemComponentProps>(
  (
    { text, icon, route, open, disabled, onClick }: ListItemComponentProps,
    ref
  ) => {
    const location = useLocation();
    const isSelected = location.pathname === route;

    return (
      <ListItem disablePadding sx={{ display: "block" }}>
        {!open ? (
          <Tooltip title={text} placement="right" disableInteractive>
            <ListItemButton
              disabled={disabled}
              sx={{
                minHeight: 2,
                backgroundColor: isSelected ? "#263238" : "transparent",
                "&:hover": {
                  backgroundColor: isSelected
                    ? "#1c262d"
                    : "rgba(0, 0, 0, 0.08)",
                },
                borderRadius: "8px",
                margin: "8%",
                width: "auto",
                padding: "10%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={onClick}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  justifyContent: "center",
                  color: isSelected ? "#4dabf5" : "inherit",
                  ...(open ? { mr: 3 } : {}),
                }}
              >
                {icon}
              </ListItemIcon>
            </ListItemButton>
          </Tooltip>
        ) : (
          <ListItemButton
            disabled={disabled}
            sx={{
              height: "40px",
              backgroundColor: isSelected ? "#263238" : "transparent",
              "&:hover": {
                backgroundColor: isSelected ? "#1c262d" : "rgba(0, 0, 0, 0.08)",
              },
              borderRadius: "8px",
              margin: "3%",
              padding: "5%",
            }}
            onClick={onClick}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                justifyContent: "center",
                ...(open && {
                  mr: 1,
                }),
                color: isSelected ? "#4dabf5" : "inherit",
              }}
            >
              {icon}
            </ListItemIcon>
            <ListItemText
              primary={text}
              sx={{
                ...(open
                  ? {
                      opacity: 1,
                    }
                  : {
                      opacity: 0,
                    }),
              }}
            />
          </ListItemButton>
        )}
      </ListItem>
    );
  }
);

export default SideBarItem;
