import { IFacebookFeed } from "../types/FacebookFeed.type";
import http from "../utils/http-common";

class FacebookService {
  getFeed(id: any) {
    return http.get<IFacebookFeed>(`/api/facebook/${id}/feed`);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new FacebookService();
