import PageComponent from "../../components/Page/PageComponent";
import WebsitePage from "../../components/Websites/Websites";

const Websites = () => {
  return (
    <PageComponent
      headerTitle="Weboldalak"
      breadCrumbMain="Beállítások"
      children={<WebsitePage />}
    />
  );
};

export default Websites;
