import http from "../utils/http-common";
import IUserData from "../types/User.type";

class UserDataService {
  getAll() {
    return http.get<Array<IUserData>>("/api/users/");
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new UserDataService();
