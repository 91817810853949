import { Notifications } from "@mui/icons-material";
import { Box, Grid, Skeleton, Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../Buttons/PrimaryButton";
import NotificationCard from "./NotificationCard";
import NotificationService from "../../services/Notification.service";
import INotification from "../../types/Notification.type";

interface NotificationListProps {
  button?: boolean;
  maxNotifications?: number;
}

const NotificationList: React.FC<NotificationListProps> = (props) => {
  const navigate = useNavigate();
  const [data, setData] = useState<INotification[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const notificationsPerPage = 8;

  const maxNotifications = props.maxNotifications || data.length;

  const fetchNotifications = () => {
    setLoading(true);
    NotificationService.getAll()
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching notifications:", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchForRead = () => {
    NotificationService.getAll()
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.error("Error fetching notifications:", err);
      });
  };

  const displayedNotifications = data.slice(0, maxNotifications);

  if (props.maxNotifications) {
    return (
      <Box sx={{ margin: "auto" }}>
        <Grid container spacing={2}>
          {loading
            ? Array.from({ length: 6 }).map((_, index) => (
                <Grid item xs={12} sm={12} md={6} key={index}>
                  <Skeleton
                    sx={{ bgcolor: "grey.900" }}
                    animation="wave"
                    variant="rectangular"
                    width="100%"
                    height={150}
                  />
                </Grid>
              ))
            : displayedNotifications.map((notification, index) => (
                <Grid item xs={12} sm={12} md={6} key={index}>
                  <NotificationCard
                    refreshNotification={fetchForRead}
                    id={notification.id}
                    websiteId={notification.websiteId}
                    createdAt={notification.createdAt}
                    isRecent={notification.isRecent}
                    email={notification.email}
                    phone={notification.phone}
                    content={notification.content}
                  />
                </Grid>
              ))}
        </Grid>

        {props.button && (
          <PrimaryButton
            icon={<Notifications />}
            onClick={() => navigate("/dashboard/notifications")}
            title="További értesítések"
          />
        )}
      </Box>
    );
  }

  const indexOfLastNotification = currentPage * notificationsPerPage;
  const indexOfFirstNotification =
    indexOfLastNotification - notificationsPerPage;
  const currentNotifications = displayedNotifications.slice(
    indexOfFirstNotification,
    indexOfLastNotification
  );

  const totalPages = Math.ceil(
    displayedNotifications.length / notificationsPerPage
  );

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  return (
    <Box sx={{ margin: "auto" }}>
      <Grid container spacing={2}>
        {loading
          ? Array.from({ length: 6 }).map((_, index) => (
              <Grid item xs={12} sm={12} md={6} key={index}>
                <Skeleton
                  sx={{ bgcolor: "grey.900" }}
                  animation="wave"
                  variant="rectangular"
                  width="100%"
                  height={150}
                />
              </Grid>
            ))
          : currentNotifications.map((notification, index) => (
              <Grid item xs={12} sm={12} md={6} key={index}>
                <NotificationCard
                  refreshNotification={fetchForRead}
                  id={notification.id}
                  websiteId={notification.websiteId}
                  createdAt={notification.createdAt}
                  isRecent={notification.isRecent}
                  email={notification.email}
                  phone={notification.phone}
                  content={notification.content}
                />
              </Grid>
            ))}
      </Grid>

      <Box
        sx={{ display: "flex", justifyContent: "center", marginTop: "16px" }}
      >
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="standard"
          size="small"
          disabled={loading}
        />
      </Box>

      {props.button && (
        <PrimaryButton
          icon={<Notifications />}
          onClick={() => navigate("/dashboard/notifications")}
          title="További értesítések"
        />
      )}
    </Box>
  );
};

export default NotificationList;
