import { NotificationsOutlined } from "@mui/icons-material";
import NotificationList from "../../components/Notifications/NotificationList";
import PageComponent from "../../components/Page/PageComponent";
import CardSection from "../../components/Sections/CardSection";

const Notifications = () => {
  return (
    <PageComponent
      headerTitle="Értesítések"
      breadCrumbMain="Alap"
      children={
        <CardSection
          icon={NotificationsOutlined}
          title="Értesítések"
          children={<NotificationList />}
        />
      }
    />
  );
};

export default Notifications;
