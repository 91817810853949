import { useEffect, useState } from "react";
import FacebookService from "../../services/Facebook.service";
import { getActualUserWebsiteId } from "../../utils/token";
import { Box, Typography, Grid, Skeleton, Alert } from "@mui/material";
import { IFacebookPost } from "../../types/FacebookFeed.type";

const FacebookFeed = () => {
  const [data, setData] = useState<IFacebookPost[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchFacebookFeed = () => {
    FacebookService.getFeed(getActualUserWebsiteId())
      .then((res) => {
        setData(res.data.data.slice(0, 2));
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching Facebook feed:", err);
        setError("Failed to fetch feed. Please try again later.");
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchFacebookFeed();
  }, []);

  const renderAttachment = (attachment: any, index: number) => {
    return (
      <Box key={index} sx={{ marginTop: "16px" }}>
        {attachment.media?.image?.src && (
          <Grid container spacing={2}>
            {/* <Grid item xs={6} sm={4} md={2}>
              <img
                src={attachment.media.image.src}
                alt={`Attachment ${index}`}
                style={{
                  width: "100%",
                  height: "auto",
                  maxWidth: "100%",
                  borderRadius: "8px",
                }}
              />
            </Grid> */}
            {attachment.subattachments?.data &&
              attachment.subattachments.data.length > 0 && (
                <>
                  {attachment.subattachments.data.map(
                    (subattachment: any, subIndex: number) => (
                      <Grid item xs={6} sm={4} md={2} key={subIndex}>
                        <img
                          src={subattachment.media?.image?.src}
                          alt={`Subattachment ${subIndex}`}
                          style={{
                            width: "100%",
                            height: "auto",
                            maxWidth: "100%",
                            borderRadius: "8px",
                          }}
                        />
                      </Grid>
                    )
                  )}
                </>
              )}
          </Grid>
        )}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        marginTop: "16px",
        width: "100%",
        color: "text.secondary",
      }}
    >
      {loading ? (
        <Grid container spacing={2} justifyContent="center">
          {[...Array(2)].map((_, index) => (
            <Grid item xs={12} sm={12} md={6} key={index}>
              <Box
                sx={{
                  padding: "15px",
                  boxShadow: 3,
                  borderRadius: 3,
                  borderColor: "#303030",
                  borderStyle: "solid",
                  borderWidth: 1,
                }}
              >
                <Skeleton
                  sx={{ bgcolor: "grey.900" }}
                  variant="text"
                  width="60%"
                  animation="wave"
                />
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  width="100%"
                  height={150}
                  sx={{ marginTop: "16px", bgcolor: "grey.900" }}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : data.length === 0 ? (
        <Typography>No posts available</Typography>
      ) : (
        <Grid container spacing={2} justifyContent="center">
          {data.map((post) => (
            <Grid item xs={12} sm={12} md={6} key={post.id}>
              <Box
                sx={{
                  padding: "15px",
                  boxShadow: 3,
                  borderRadius: 3,
                  borderColor: "#303030",
                  borderStyle: "solid",
                  borderWidth: 1,
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: 14,
                    whiteSpace: "pre-line",
                  }}
                >
                  {post.message || "No message"}
                </Typography>
                {post.attachments?.data && post.attachments.data.length > 0 ? (
                  post.attachments.data.map((attachment, index) =>
                    renderAttachment(attachment, index)
                  )
                ) : (
                  <Typography variant="body2" color="text.secondary">
                    Nincs kép{" "}
                  </Typography>
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default FacebookFeed;
