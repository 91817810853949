import { EmailOutlined } from "@mui/icons-material";
import { Badge, Box, Card, CardContent, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomDialog from "../Dialog/CustomDialog";
import moment from "moment";
import INotification from "../../types/Notification.type";
import NotificationService from "../../services/Notification.service";

interface NotificationCardProps extends INotification {
  refreshNotification: (updatedNotification: INotification) => void;
}

const NotificationCard: React.FC<NotificationCardProps> = (props) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    NotificationService.setRead(props.id);
  };

  const handleClose = () => {
    setOpen(false);
    const updatedNotification = { ...props, isRecent: false };
    props.refreshNotification(updatedNotification);
  };

  return (
    <Card
      sx={{
        marginBottom: 1,
        boxShadow: 3,
        borderRadius: 3,
        borderColor: "#303030",
        borderStyle: "solid",
        borderWidth: 1,
        transition: "background-color 0.3s ease",
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "rgba(0, 0, 0, 0.05)",
        },
      }}
    >
      <CardContent
        onClick={() => {
          handleOpen();
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" gap={1}>
            <EmailOutlined />
            <Typography variant="body2" color="text.secondary">
              {moment(props.createdAt).local().format("YYYY.MM.DD. HH:mm")}
            </Typography>
          </Box>
          {props.isRecent === true ? (
            <Badge
              color="error"
              variant="dot"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            />
          ) : null}
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="body2" color="text.secondary">
            {props.phone}
          </Typography>
        </Box>
        <Typography style={{ fontSize: 16, color: "#4dabf5" }} component="h3">
          {props.email}
        </Typography>
        <Typography
          variant="body1"
          color="text.primary"
          sx={{
            marginTop: 1,
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
          }}
        >
          {props.content}
        </Typography>
      </CardContent>
      <CustomDialog
        from={props.email}
        date={moment(props.createdAt).local().format("YYYY.MM.DD. HH:mm")}
        isOpen={open}
        phone={props.phone}
        handleOpen={handleOpen}
        handleClose={handleClose}
        content={props.content}
      />
    </Card>
  );
};

export default NotificationCard;
