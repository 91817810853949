import { ReactNode } from "react";
import BreadCrumb from "./BreadCrumb";

interface PageProps {
  headerTitle: string;
  children?: ReactNode;
  breadCrumbMain: string;
}
const PageComponent: React.FC<PageProps> = (props) => {
  return (
    <>
      <BreadCrumb mainTitle={props.breadCrumbMain} title={props.headerTitle} />
      {props.children}
    </>
  );
};

export default PageComponent;
