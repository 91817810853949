import * as React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Tooltip from "@mui/material/Tooltip";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";

import {
  Article,
  Dashboard,
  Facebook,
  Google,
  Group,
  Image,
  LogoutRounded,
  MenuOpen,
  Notifications,
  Settings,
  WebAsset,
} from "@mui/icons-material";
import SideBarItem from "./SideBarItem";
import SidebarSection from "./SidebarSection";
import { Avatar } from "@mui/material";
import { useAuth } from "../../providers/AuthProvider";
import {
  getActualUserIsAdmin,
  getActualUserPermissions,
} from "../../utils/token";
import { permissions } from "../../utils/permissions";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: 0,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: "#000000",
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
  ...(useMediaQuery(theme.breakpoints.down("sm")) &&
    !open && {
      display: "block",
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 1200,
      transition: "transform 0.3s ease",
      transform: "translateX(-100%)",
    }),
}));

const isAdmin = getActualUserIsAdmin();

const hasPermission = (
  permissions: string[],
  requiredPermission: string
): boolean => {
  return permissions.includes(requiredPermission);
};

export default function DashboardLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const decodedToken = getActualUserPermissions();
  const userPermissions = decodedToken ? decodedToken : [];

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);
  const { logout } = useAuth();

  const handleItemClick = (route: string) => {
    setOpen(false);
    navigate(route);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      event.target instanceof HTMLElement &&
      !event.target.closest(".MuiDrawer-root")
    ) {
      setOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ ml: "auto" }}>
              <IconButton
                onClick={logout}
                color="inherit"
                aria-label="logout"
                sx={{
                  color: "white",
                  border: "2px solid #3278ad",
                  borderRadius: "12px",
                  padding: "6px 12px",
                  ":hover": {
                    borderColor: "#4dabf5",
                    backgroundColor: "transparent",
                  },
                }}
              >
                <LogoutRounded />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0 16px",
            }}
          >
            <Avatar
              sx={{
                borderColor: "#4dabf5",
                borderStyle: "solid",
                color: "white",
                backgroundColor: "transparent",
                width: 40,
              }}
            >
              P
            </Avatar>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? <ChevronRightIcon /> : <MenuOpen />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {open && <SidebarSection text="Alap" />}
            {hasPermission(userPermissions, permissions.pages) && (
              <Tooltip title="Dashboard" placement="right">
                <SideBarItem
                  text="Kezelőfelület"
                  icon={<Dashboard />}
                  route="/dashboard"
                  open={open}
                  onClick={() => handleItemClick("/dashboard")}
                />
              </Tooltip>
            )}
            {hasPermission(userPermissions, permissions.notifications) && (
              <Tooltip title="Értesítések" placement="right">
                <SideBarItem
                  text="Értesítések"
                  icon={<Notifications />}
                  route="/dashboard/notifications"
                  onClick={() => handleItemClick("/dashboard/notifications")}
                  open={open}
                />
              </Tooltip>
            )}
            {hasPermission(userPermissions, permissions.gallery) && (
              <Tooltip title="Galéria" placement="right">
                <SideBarItem
                  text="Galéria"
                  icon={<Image />}
                  route="/dashboard/gallery"
                  onClick={() => handleItemClick("/dashboard/gallery")}
                  open={open}
                />
              </Tooltip>
            )}
            {hasPermission(userPermissions, permissions.pages) && (
              <Tooltip title="Oldalak" placement="right">
                <SideBarItem
                  text="Oldalak"
                  icon={<Article />}
                  route="/dashboard/pages"
                  onClick={() => handleItemClick("/dashboard/pages")}
                  open={open}
                />
              </Tooltip>
            )}
            <Divider />
            {open && <SidebarSection text="Integrációk" />}
            {hasPermission(userPermissions, permissions.google) && (
              <Tooltip title="Google" placement="right">
                <SideBarItem
                  text="Google"
                  icon={<Google />}
                  route="/dashboard/google"
                  disabled={true}
                  onClick={() => handleItemClick("/dashboard/google")}
                  open={open}
                />
              </Tooltip>
            )}
            {hasPermission(userPermissions, permissions.facebook) && (
              <Tooltip title="Facebook" placement="right">
                <SideBarItem
                  text="Facebook"
                  icon={<Facebook />}
                  route="/dashboard/facebook"
                  onClick={() => handleItemClick("/dashboard/facebook")}
                  open={open}
                />
              </Tooltip>
            )}
            <Divider />
            {open && <SidebarSection text="Beállítások" />}
            <>
              <Tooltip title="Weboldal beállítások" placement="right">
                <SideBarItem
                  text="Weboldal beállítások"
                  icon={<Settings />}
                  route="/dashboard/websitesettings"
                  disabled={true}
                  open={open}
                  onClick={() => handleItemClick("/dashboard/websitesettings")}
                />
              </Tooltip>
            </>
            {isAdmin && (
              <>
                <Tooltip title="Felhasználók" placement="right">
                  <SideBarItem
                    text="Felhasználók"
                    icon={<Group />}
                    route="/dashboard/users"
                    open={open}
                    onClick={() => handleItemClick("/dashboard/users")}
                  />
                </Tooltip>
                <Tooltip title="Weboldalak" placement="right">
                  <SideBarItem
                    text="Weboldalak"
                    icon={<WebAsset />}
                    route="/dashboard/websites"
                    open={open}
                    onClick={() => handleItemClick("/dashboard/websites")}
                  />
                </Tooltip>
              </>
            )}
          </List>
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 2,
          marginLeft: isSmallScreen ? "0" : `calc(${theme.spacing(8)} + 1px)`,
          transition: theme.transitions.create(
            ["margin-left", "margin-right"],
            {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.standard,
            }
          ),
        }}
      >
        <DrawerHeader />
        {children}
      </Box>
    </>
  );
}
