import { Button } from "@mui/material";
import React, { ReactNode } from "react";

interface PrimaryButtonProps {
  title: string;
  href?: string;
  icon?: ReactNode;
  disabled?: boolean;
  onClick?: () => void;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = (props) => {
  return (
    <Button
      startIcon={
        React.isValidElement(props.icon)
          ? React.cloneElement(props.icon as React.ReactElement, {
              style: { color: "#4dabf5" },
            })
          : null
      }
      onClick={props.onClick}
      href={props.href}
      sx={{
        marginTop: 1,
        backgroundColor: "#1c262d",
        color: "white",
        borderRadius: "8px",
        boxShadow: "1",
        textTransform: "none",
        "&:hover": {
          backgroundColor: "#263238",
          boxShadow: 0,
          transition: "background-color 0.3s ease",
        },
      }}
      size="large"
      variant="contained"
    >
      {props.title}
    </Button>
  );
};

export default PrimaryButton;
