import React from "react";
import PageComponent from "../../components/Page/PageComponent";
import CardSection from "../../components/Sections/CardSection";
import { PersonOutlined } from "@mui/icons-material";
import UserList from "../../components/Users/UsersList";

const Users = () => {
  return (
    <PageComponent
      headerTitle="Felhasználók"
      breadCrumbMain="Beállítások"
      children={
        <CardSection
          icon={PersonOutlined}
          title="Felhasználók kezelése"
          children={<UserList />}
        />
      }
    />
  );
};

export default Users;
