import React, { ReactNode } from "react";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";

interface CardSectionProps {
  icon: SvgIconComponent;
  title: string;
  children?: ReactNode;
  buttonText?: ReactNode;
  buttonAction?: () => void;
}

const CardSection: React.FC<CardSectionProps> = ({
  icon: Icon,
  title,
  children,
  buttonText,
}) => {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        width: "100%",
        boxShadow: 3,
        borderRadius: 3,
        borderColor: "#303030",
        borderStyle: "solid",
        marginBottom: "20px",
      }}
    >
      <CardContent
        sx={{
          flex: 1,
          "&:last-child": {
            paddingBottom: 0,
          },
        }}
      >
        <Box display="flex" gap={1} marginBottom={1}>
          <Icon style={{ color: "#4dabf5" }} />
          <Typography style={{ fontSize: 16 }} component="h3" gutterBottom>
            {title}
          </Typography>
        </Box>
        {children}
        <Button>{buttonText}</Button>
      </CardContent>
    </Card>
  );
};

export default CardSection;
