import React, { useState } from "react";
import {
  Grid,
  Box,
  Typography,
  IconButton,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSnackbar } from "notistack";
import IGallery from "../../types/Gallery.type";
import GalleryService from "../../services/Gallery.service";

interface GalleryListProps {
  images: IGallery[];
  loading: boolean;
  error: string | null;
  refreshGallery: () => void;
}

const GalleryList: React.FC<GalleryListProps> = ({
  images,
  loading,
  error,
  refreshGallery,
}) => {
  const [deletingImageId, setDeletingImageId] = useState<string | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleDelete = async (imageId: string) => {
    setDeletingImageId(imageId);
    try {
      await GalleryService.delete(imageId);
      enqueueSnackbar("Kép sikeresen törölve!", { variant: "success" });
      refreshGallery();
    } catch (error) {
      enqueueSnackbar("Hiba történt a törlés során.", { variant: "error" });
    } finally {
      setDeletingImageId(null);
    }
  };

  return (
    <Box sx={{ width: "100%", padding: "16px" }}>
      {loading ? (
        <Grid container spacing={2}>
          {[...Array(6)].map((_, index) => (
            <Grid item xs={6} sm={6} md={4} key={index}>
              <Skeleton
                sx={{ borderRadius: "8px", bgcolor: "grey.900" }}
                animation="wave"
                variant="rectangular"
                width="100%"
                height={200}
              />
            </Grid>
          ))}
        </Grid>
      ) : error ? (
        <Box sx={{ textAlign: "center", marginTop: "20px" }}>
          <Typography variant="h6" color="error">
            {error}
          </Typography>
        </Box>
      ) : images.length === 0 ? (
        <Box sx={{ textAlign: "center", marginTop: "20px" }}>
          <Typography variant="h6">Nincsenek elérhető képek.</Typography>
        </Box>
      ) : (
        <Grid container spacing={2}>
          {images.map((imageData) => (
            <Grid item xs={6} sm={6} md={4} key={imageData.id}>
              <Box
                sx={{
                  width: "100%",
                  height: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                  borderRadius: "8px",
                  position: "relative",
                }}
              >
                <img
                  src={imageData.image}
                  alt={`Gallery item ${imageData.id}`}
                  loading="lazy"
                  style={{
                    maxHeight: "auto",
                    width: "100%",
                    objectFit: "contain",
                    borderRadius: "8px",
                  }}
                />
                {deletingImageId === imageData.id && (
                  <CircularProgress
                    size={50}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      zIndex: 10,
                    }}
                  />
                )}
                <IconButton
                  onClick={() => handleDelete(imageData.id)}
                  disabled={deletingImageId === imageData.id}
                  sx={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    color: "#4dabf5",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.7)",
                    },
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default GalleryList;
