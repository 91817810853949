import { Routes, Route, Navigate } from "react-router-dom";
import AuthLayout from "./_auth/AuthLayout";
import RootLayout from "./_root/RootLayout";
import "./App.css";
import Home from "./_root/pages/Home";
import Pages from "./_root/pages/Pages";
import Google from "./_root/pages/Google";
import FacebookPage from "./_root/pages/Facebook";
import Websites from "./_root/pages/Websites";
import Users from "./_root/pages/Users";
import LoginPage from "./_auth/pages/Login";
import Notifications from "./_root/pages/Notifications";
import Gallery from "./_root/pages/Gallery";
import { useAuth } from "./providers/AuthProvider";

const App = () => {
  const { isAuthenticated } = useAuth();

  return (
    <Routes>
      {/* Auth routes */}
      <Route element={<AuthLayout />}>
        <Route
          path="/"
          element={
            isAuthenticated ? <Navigate to="/dashboard" /> : <LoginPage />
          }
        />
      </Route>

      {/* Root routes */}
      <Route element={<RootLayout />}>
        <Route path="/dashboard" element={<Home />} />
        <Route path="/dashboard/notifications" element={<Notifications />} />
        <Route path="/dashboard/gallery" element={<Gallery />} />
        <Route path="/dashboard/pages" element={<Pages />} />
        <Route path="/dashboard/google" element={<Google />} />
        <Route path="/dashboard/facebook" element={<FacebookPage />} />
        <Route path="/dashboard/users" element={<Users />} />
        <Route path="/dashboard/websites" element={<Websites />} />
      </Route>
    </Routes>
  );
};

export default App;
