import React, { useEffect, useState } from "react";
import {
  DesktopWindowsOutlined,
  PhoneAndroidOutlined,
} from "@mui/icons-material";
import { Box, Card, CardContent, Typography, Skeleton } from "@mui/material";
import IVisitor from "../../types/Visitor.type";
import VisitorService from "../../services/Visitor.service";
import { getActualUserWebsiteId } from "../../utils/token";

const VisitorsCard = () => {
  const [visitorData, setVisitorData] = useState<IVisitor | null>(null);

  useEffect(() => {
    const fetchVisitorData = async () => {
      const websiteId = getActualUserWebsiteId();

      if (!websiteId) {
        console.error("Website ID is required!");
        return;
      }

      try {
        const response = await VisitorService.getAll(websiteId);
        setVisitorData(response.data);
      } catch (error) {
        console.error("Error fetching visitor data:", error);
      }
    };

    fetchVisitorData();
  }, []);

  if (!visitorData) {
    return (
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          gap: 2,
        }}
      >
        <Card
          sx={{
            boxShadow: 3,
            borderRadius: 3,
            borderColor: "#303030",
            borderStyle: "solid",
            borderWidth: 1,
            transition: "background-color 0.3s ease",
            width: {
              xs: "100%",
              sm: "48%",
              md: "30%",
            },
          }}
        >
          <CardContent>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              mb={2}
            >
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                animation="wave"
                variant="rectangular"
                width={50}
                height={50}
              />
              <Skeleton variant="text" width="60%" />
            </Box>
            <Skeleton variant="text" width="80%" />
          </CardContent>
        </Card>

        <Card
          sx={{
            boxShadow: 3,
            borderRadius: 3,
            borderColor: "#303030",
            borderStyle: "solid",
            borderWidth: 1,
            transition: "background-color 0.3s ease",
            width: {
              xs: "100%",
              sm: "48%",
              md: "30%",
            },
          }}
        >
          <CardContent>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              mb={2}
            >
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                animation="wave"
                variant="rectangular"
                width={50}
                height={50}
              />
              <Skeleton variant="text" width="60%" />
            </Box>
            <Skeleton variant="text" width="80%" />
          </CardContent>
        </Card>

        <Card
          sx={{
            boxShadow: 3,
            borderRadius: 3,
            borderColor: "#303030",
            borderStyle: "solid",
            borderWidth: 1,
            transition: "background-color 0.3s ease",
            width: {
              xs: "100%",
              sm: "48%",
              md: "30%",
            },
          }}
        >
          <CardContent>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              mb={2}
            >
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                animation="wave"
                variant="rectangular"
                width={50}
                height={50}
              />
              <Skeleton variant="text" width="60%" />
            </Box>
            <Skeleton variant="text" width="80%" />
          </CardContent>
        </Card>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
      }}
    >
      {/* Desktop Card */}
      <Card
        sx={{
          boxShadow: 3,
          borderRadius: 3,
          borderColor: "#303030",
          borderStyle: "solid",
          borderWidth: 1,
          transition: "background-color 0.3s ease",
          width: {
            xs: "100%",
            sm: "48%",
            md: "48%",
          },
        }}
      >
        <CardContent>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            mb={2}
          >
            <DesktopWindowsOutlined
              sx={{ fontSize: 50, color: "white", marginBottom: "10px" }}
            />
            <Typography
              variant="h1"
              sx={{ color: "#4dabf5", fontSize: "2rem" }}
            >
              {visitorData.result.desktop}
            </Typography>
          </Box>
          <Typography
            style={{ fontSize: 16, color: "white", textAlign: "center" }}
            component="h3"
          >
            Számítógép
          </Typography>
        </CardContent>
      </Card>

      {/* Mobile Card */}
      <Card
        sx={{
          boxShadow: 3,
          borderRadius: 3,
          borderColor: "#303030",
          borderStyle: "solid",
          borderWidth: 1,
          transition: "background-color 0.3s ease",
          width: {
            xs: "100%",
            sm: "48%",
            md: "48%",
          },
        }}
      >
        <CardContent>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            mb={2}
          >
            <PhoneAndroidOutlined
              sx={{ fontSize: 50, color: "white", marginBottom: "10px" }}
            />
            <Typography
              variant="h1"
              sx={{ color: "#4dabf5", fontSize: "2rem" }}
            >
              {visitorData.result.mobile}
            </Typography>
          </Box>
          <Typography
            style={{ fontSize: 16, color: "white", textAlign: "center" }}
            component="h3"
          >
            Telefon/Táblagép
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default VisitorsCard;
