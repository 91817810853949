import { Navigate, Outlet } from "react-router-dom";
import DashboardLayout from "../components/Sidebar/DashboardLayout";
import { useAuth } from "../providers/AuthProvider";
import { useEffect, useState } from "react";
import { getTokenExpiration } from "../utils/token";

const RootLayout = () => {
  const { isAuthenticated } = useAuth();
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(true);
    const tokenExpiration = getTokenExpiration();
    const isTokenExpired =
      tokenExpiration && tokenExpiration * 1000 < Date.now();

    if (isTokenExpired) {
      localStorage.removeItem("authToken");
    }
  }, []);

  if (!isChecked) {
    return null;
  }

  return isAuthenticated ? (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  ) : (
    <Navigate to="/" replace />
  );
};

export default RootLayout;
