import IGallery from "../types/Gallery.type";
import http from "../utils/http-common";
import httpFile from "../utils/http-common-file";

class GalleryService {
  getAll(id: any) {
    return http.get<IGallery>(`/api/gallery/${id}`);
  }

  upload(formData: FormData) {
    return httpFile.post<IGallery>("/api/gallery/upload", formData);
  }

  delete(id: any) {
    return http.delete<IGallery>(`/api/gallery/${id}`);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new GalleryService();
